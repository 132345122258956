import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby";

class DynamicPriceCalculationButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldScroll: 0,
            show: false,
        };
    }

    componentDidMount() {
        // hide the btn when the footer is visible
        // else show when scrolling up and down
        document.addEventListener("scroll", () => {
            var element = document.getElementsByClassName("footer")[0];
            var position = element.getBoundingClientRect();
            if (position.top < window.innerHeight && position.bottom >= 0) {
                this.setState({ oldScroll: window.scrollY, show: false });
            } else {
                if (this.state.oldScroll != window.scrollY) {
                    this.setState({ oldScroll: window.scrollY, show: true });
                }
            }
        });
    }

    render() {
        return (
            <div className="dynamic-sticky-button">
                <div className="fixedElement is-hidden-tablet">
                    <Fade bottom when={this.state.show}>
                        <Link
                            className="button preisrechner-btn preisrechner-btn-primary preisrechner-btn-mobile-article"
                            to="/preisrechner/"
                        >
                            Jetzt kostenlos Preis berechnen
                        </Link>
                    </Fade>
                </div>
            </div>
        );
    }
}

export default DynamicPriceCalculationButton;
